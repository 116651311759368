<template>
  <div ref="container" v-show="isShow" class="bg-white border rounded-1 border-#ebeef5 w-full">
    <el-scrollbar ref="scrollbarRef" max-height="300px">
      <ul
        v-loading="loading"
        :class="{
          'flex items-center': contentList.length === 0
        }"
        class="pl-0 m-0 list-outside list-none min-h-58px"
      >
        <template v-if="contentList.length > 0">
          <router-link
            v-for="(item, index) in contentList"
            :key="item.id"
            :to="getRouterLink(item, 'line')"
            @mouseenter="selectedIndex = index"
            @mouseleave="selectedIndex = -1"
            @click="() => emit('getContentData', item)"
          >
            <li
              :class="[
                'flex flex-col gap-1 hover:bg-#f5f7fa text-gray-400 min-h-60px transition-all cursor-pointer px-10px py-10px',
                index === selectedIndex ? 'bg-#e6f7ff' : ''
              ]"
            >
              <div class="flex justify-between w-full items-center md:flex-row flex-col">
                <!-- 高亮顯示的車牌號 -->
                <p
                  class="w-full md:w-200px line-clamp-1 !m-0 text-black"
                  v-html="highlight(item.plate_number, true)"
                ></p>

                <div
                  v-if="getCustomDisplayText(item, searchResultsFields.main)"
                  class="flex-1 w-full md:text-right"
                >
                  <span
                    v-html="highlight(getCustomDisplayText(item, searchResultsFields.main))"
                  ></span>
                </div>
              </div>

              <div v-if="getCustomDisplayText(item, searchResultsFields.details)" class="w-full">
                <span
                  v-html="highlight(getCustomDisplayText(item, searchResultsFields.details))"
                ></span>
              </div>

              <p
                v-if="getCustomDisplayText(item, searchResultsFields.additional)"
                v-html="highlight(getCustomDisplayText(item, searchResultsFields.additional))"
                class="!m-0"
              ></p>

              <p
                v-if="
                  searchResultsFields.extra && getCustomDisplayText(item, searchResultsFields.extra)
                "
                v-html="highlight(getCustomDisplayText(item, searchResultsFields.extra))"
                class="!m-0 md:hidden"
              ></p>
            </li>
            <el-divider v-if="index < contentList.length - 1" class="m-0" />
          </router-link>
        </template>

        <li
          v-show="contentList.length === 0 && !loading && hasSearched"
          class="text-center py-5px w-full"
        >
          未搜尋到車牌號碼/取車人/取車人電話
        </li>
      </ul>
    </el-scrollbar>

    <div class="bg-#F5F5F5 p-4 flex md:flex-row flex-col justify-between items-center">
      <div>
        <p class="text-black m-0">未搜尋到車牌號碼/取車人/取車人電話？</p>
        <p class="text-black m-0 text-gray-400">點擊即可快速新增車籍資料</p>
      </div>

      <el-button @click="handleAdd">新增</el-button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, nextTick, onMounted, onBeforeUnmount } from 'vue'
import { useRouter } from 'vue-router'
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'

const router = useRouter()
// router.beforeEach(async (to, from) => {
//   // console.log('to ===>', to, 'from ===>', from)
//   if (to) {
//     console.log('to ===>', to)
//     handleHide()
//   }
// })

const props = defineProps({
  contentList: {
    type: Array,
    default: () => []
  }
})

const emit = defineEmits(['add', 'getContentData'])

// Refs
const container = ref(null)
const scrollbarRef = ref(null)

// 斷點
const breakpoints = useBreakpoints(breakpointsTailwind)
const isSmallerThanMd = breakpoints.smaller('md')

// Models
const searchValue = defineModel('searchValue', {
  type: String,
  default: ''
})
const loading = defineModel('loading', {
  type: Boolean,
  default: false
})

// 狀態
const isShow = ref(false)
const hasSearched = ref(false)
const selectedIndex = ref(0) // -1 表示沒有選中任何項目, 0 表示選中第一個項目

// 監聽 contentList 更新時重置 selectedIndex
watch(
  () => props.contentList,
  () => {
    selectedIndex.value = 0
  }
)

// 顯示此元件
const handleShow = () => {
  setTimeout(() => {
    isShow.value = true
    // searchInputRef.value.focus()
  }, 0)
}

// 隱藏此元件
const handleHide = () => {
  setTimeout(() => {
    isShow.value = false
    hasSearched.value = false
  }, 0)
}

// 點擊新增
const handleAdd = () => {
  emit('add', {
    searchValue: searchValue.value
  })
}

// 控制是否顯示搜尋結果
const handleSearchResults = (visible) => {
  loading.value = !visible
  hasSearched.value = visible
}

// 取得 router 配置
const getRouterLink = (row) => {
  const { vehicle_id, ...rest } = row // 解構並排除 vehicle_id
  const query = { ...rest, id: vehicle_id } // 重新組合並將 vehicle_id 改為 id

  return {
    name: 'cardetail',
    query: {
      plate_number: query.plate_number,
      car_type_id: query.car_type_id || '3',
      engine_number: query.engine_number,
      body_number: query.body_number,
      id: query.id,
      car_type_name: query.car_type_name,
      mileage: 'open',
      client_name: query.name,
      phone: query.phone
    }
  }
}

// 根據螢幕尺寸決定顯示哪些欄位
const searchResultsFields = computed(() => {
  const fields = {
    // 顯示的欄位
    main: ['name', 'phone'],
    details: ['brand', 'car_type_name', 'model_name', 'color'],
    additional: [],
    extra: [] // 額外的欄位
  }

  if (isSmallerThanMd.value) {
    fields.additional = ['engine_number', 'body_number']
  } else {
    fields.details.push('engine_number', 'body_number')
  }

  return fields
})

// 取得自訂顯示文字
function getCustomDisplayText(item, fields) {
  if (!fields) {
    console.error('fields is required')
    return ''
  }

  return fields
    .map((field) => item[field])
    .filter(Boolean)
    .join(' / ')
}

// 轉義 RegExp 特殊字符
function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

// 轉義 HTML，防止 XSS
function escapeHtml(text) {
  const map = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#039;'
  }
  return text.replace(/[&<>"']/g, (m) => map[m])
}

// 高亮匹配文字
function highlight(text, isPlateNumber = false) {
  if (!searchValue.value) return escapeHtml(text)

  let escapedSearch = escapeRegExp(searchValue.value)

  if (isPlateNumber) {
    // 將 searchValue 轉換為允許中間有 '-' 的正則表達式
    escapedSearch = escapedSearch
      .split('')
      .map((char) => `${escapeRegExp(char)}-?`)
      .join('')
    // 移除最後一個 '-?'
    escapedSearch = escapedSearch.slice(0, -2)
  }

  const regex = new RegExp(`(${escapedSearch})`, 'gi')
  return escapeHtml(text).replace(regex, '<span style="color:red">$1</span>')
}

// 鍵盤操作處理
const handleKeyDown = (event) => {
  if (!isShow.value) return

  const { key } = event
  if (key === 'ArrowDown') {
    event.preventDefault()
    if (selectedIndex.value < props.contentList.length - 1) {
      selectedIndex.value += 1
      scrollToItem(selectedIndex.value)
    }
  } else if (key === 'ArrowUp') {
    event.preventDefault()
    if (selectedIndex.value > 0) {
      selectedIndex.value -= 1
      scrollToItem(selectedIndex.value)
    }
  } else if (key === 'Enter') {
    event.preventDefault()
    if (selectedIndex.value >= 0 && selectedIndex.value < props.contentList.length) {
      const selectedItem = props.contentList[selectedIndex.value]
      emit('getContentData', selectedItem)
      const link = getRouterLink(selectedItem)
      router.push(link)
      handleHide()
    }
  }
}
// 滾動到選中的項目
const scrollToItem = (index) => {
  nextTick(() => {
    const listItems = container.value.querySelectorAll('li')
    if (listItems[index]) {
      listItems[index].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest'
      })
    }
  })
}

onMounted(() => {
  window.addEventListener('keydown', handleKeyDown)
})

onBeforeUnmount(() => {
  window.removeEventListener('keydown', handleKeyDown)
})

// 暴露給父元件
defineExpose({
  handleShow,
  handleHide,
  handleSearchResults
})
</script>

<style scoped>
.highlight {
  color: red;
  font-weight: bold;
}
</style>
