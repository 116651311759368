import request from '@/utils/axiosReq'
// 管理員登入
export function LoginReq(data) {
  return request({
    url: '/Employee/login/',
    data,
    method: 'post',
    bfLoading: false,
    isParams: false,
    isAlertErrorMsg: true,
    headers: {
      Authorization: 'be9b5912-ab29-4e55-88e1-c11b2994fa23' // 某些原因, 這裡是固定的
    }
  })
}
export function LoginOutReq(token) {
  return request({
    url: '/Employee/logout/',
    method: 'post',
    bfLoading: false,
    isParams: false,
    isAlertErrorMsg: false
  })
}

export function getInfoReq() {
  return request({
    url: '/Employee/findOneByToken/',
    method: 'get',
    bfLoading: false,
    isParams: false,
    isAlertErrorMsg: false
  })
}

// export function loginReq(data) {
//   return request({
//     url: '/integration-front/user/loginValid',
//     data,
//     method: 'post',
//     bfLoading: false,
//     isParams: true,
//     isAlertErrorMsg: false
//   })
// }

// export function getInfoReq() {
//   return request({
//     // url: '/integration-front/user/getUserInfo',
//     url : '/Manager/findOneByToken/',
//     bfLoading: false,
//     method: 'post',
//     isAlertErrorMsg: false
//   })
// }

// export function logoutReq() {
//   return request({
//     url: '/integration-front/user/loginOut',
//     method: 'post'
//   })
// }
