import request from '@/utils/axiosReq'
import { getForWorkOrder } from '@/utils/auth'

//統計圖表
export const reservelFuncs = {
  get: async function () {
    const loginStatus = JSON.parse(getForWorkOrder())
    //數據統計列表
    return request({
      url: '/Reserve/get_config/' + loginStatus.branch_id,
      method: 'get',
      bfLoading: false,
      isParams: false,
      isAlertErrorMsg: false
    })
  },

  set: async function (inputData) {
    const loginStatus = JSON.parse(getForWorkOrder())
    //數據統計列表
    return request({
      url: '/Reserve/set_config',
      method: 'post',
      data: {
        branch_id: loginStatus.branch_id, //1,
        displayname_name: inputData.displayname_name, //"分店 A",
        img_src: inputData.img_src, //"分店 A",
        address_zip: inputData.address_zip, //"中山區",
        address_city: inputData.address_city, //"台北市",
        address: inputData.address, //"地址內容地址內容",
        phone: inputData.phone, //"02-12345678",
        is_open: inputData.is_open, //1,
        service_items: inputData.service_items, //["服務A","服務B"],
        reserve_times: inputData.reserve_times, //[{"start":"11:00","end":"12:00","qty":10,"display":"時段A"}],
        is_open_notify: inputData.is_open_notify, //1,
        notify_times: inputData.notify_times, //1,
        vacation_cycle: inputData?.vacation_cycle ?? [] //休假周期配置 [0,1,2,3,4,5,6] 0 表示周日
      },
      bfLoading: false,
      isParams: false,
      isAlertErrorMsg: false
    })
  },
  // 建立預約單
  create: async function (body) {
    return request({
      url: '/Reserve/reserve_create',
      method: 'post',
      data: body,
      bfLoading: false,
      isParams: false,
      isAlertErrorMsg: false
    })
  },
  // 修改預約單
  update: async function (body) {
    return request({
      url: '/Reserve/reserve_update',
      method: 'post',
      data: body,
      bfLoading: false,
      isParams: false,
      isAlertErrorMsg: false
    })
  },
  // 通用查詢列表
  getReserveList: async function (body) {
    return request({
      url: '/Reserve/get_reserve_list',
      method: 'post',
      data: body,
      bfLoading: false,
      isParams: false,
      isAlertErrorMsg: false
    })
  },
  // 查詢預訂
  getReserve: async function (body) {
    return request({
      url: '/Reserve/get_reserve/' + body,
      method: 'get',
      bfLoading: false,
      isParams: false,
      isAlertErrorMsg: false
    })
  },
  // 店家首頁用預約列表
  // get_reserve_list_dash
  getReserveListDash: async function () {
    return request({
      url: '/Reserve/get_reserve_list_dash',
      method: 'get',
      bfLoading: false,
      isParams: false,
      isAlertErrorMsg: false
    })
  },
  // 取得預約日期列表
  getReserveDateList: async function (body) {
    // 取得特定日期（範圍也可）的營業時段
    return request({
      url: '/Reserve/get_reserve_date_list',
      method: 'post',
      data: body,
      bfLoading: false,
      isParams: false,
      isAlertErrorMsg: false
    })
  },
  // 設定預約日期
  setReserveDate: async function (body) {
    return request({
      url: '/Reserve/set_reserve_date',
      method: 'post',
      data: body,
      bfLoading: false,
      isParams: false,
      isAlertErrorMsg: false
    })
  },
  // 用otp取得預約ID
  getReserveIdByOtp: async function (otp) {
    const url = `/Reserve/get_reserve_id_by_otp/${otp}`

    return request({
      url,
      method: 'get',
      bfLoading: false,
      isParams: false,
      isAlertErrorMsg: false,
      headers: {
        Authorization: 'be9b5912-ab29-4e55-88e1-c11b2994fa23' // 某些原因, 這裡是固定的
      }
    })
  }
}
