import axios from 'axios'
import router from '@/router'
import { ElLoading, ElMessage, ElMessageBox } from 'element-plus'
import { getToken, setToken } from '@/utils/auth'
import { useUserStore } from '@/store/user'
import { initializeApp } from 'firebase/app'
import { getDatabase, ref as sRef, set } from 'firebase/database'

const firebaseConfig = {
  apiKey: 'AIzaSyD0toyKYhbSk50Bio5O6XsBaxhk1g7KpIs',
  authDomain: 'crypto-symbol-345815.firebaseapp.com',
  databaseURL: 'https://crypto-symbol-345815-default-rtdb.firebaseio.com',
  projectId: 'crypto-symbol-345815',
  storageBucket: 'crypto-symbol-345815.appspot.com',
  messagingSenderId: '296522975823',
  appId: '1:296522975823:web:76262500af21278836783a',
  measurementId: 'G-6S82HHMZPH'
}

// Initialize <Firebase></Firebase>
const app = initializeApp(firebaseConfig)

let reqConfig
let loadingE

const service = axios.create()
// 請求拦截
service.interceptors.request.use(
  (request) => {
    const token = getToken()

    // token setting
    // request.headers['AUTHORIZE_TOKEN'] = token
    // request.headers['Authorization'] = token
    if (token) request.headers['Authorization'] = token

    /* download file*/
    if (request.isDownLoadFile) {
      request.responseType = 'blob'
    }
    /* upload file*/
    if (request.isUploadFile) {
      request.headers['Content-Type'] = 'multipart/form-data'
    }
    reqConfig = request
    if (request.bfLoading) {
      loadingE = ElLoading.service({
        lock: true,
        text: '載入中',
        background: 'rgba(0, 0, 0, 0.1)'
      })
    }
    if (request.isParams) {
      request.params = request.data
      request.data = {}
    }
    return request
  },
  (err) => {
    Promise.reject(err)
  }
)

// #region 新的攔截配置
// 定義狀態碼與訊息的映射表
const STATUS_MESSAGES = {
  400: '錯誤的請求。',
  401: '未授權，請重新登入。',
  403: '拒絕訪問。',
  404: '請求的資源不存在。',
  500: '內部服務器錯誤。'
}

async function handleResponseError(error, reqConfig) {
  console.log('handleResponseError', reqConfig)
  const errorObj = {
    msg: '',
    reqUrl: `${reqConfig.baseURL}${reqConfig.url}`,
    params: reqConfig.isParams ? reqConfig.params : reqConfig.data,
    status: null,
    statusText: '',
    timestamp: new Date().toISOString()
  }

  if (error.response) {
    const { status, statusText, data } = error.response
    errorObj.status = status
    errorObj.statusText = statusText
    errorObj.msg = data?.sysMsg ?? STATUS_MESSAGES[status] ?? '未知的錯誤。'

    // console.error('登入狀態失效:', errorObj)
    
    // 特殊處理 403 和 401 狀態碼
    if ((status === 403 || status === 401) &&
      !reqConfig.url.includes('/OldMigrate/migrateData') &&
      !reqConfig.url.includes('/OldMigrate/chkLineUserId')
    ) {
      try {
        const userStore = useUserStore()
        await userStore.resetState()
        await router.push({ path: '/login' })
      } catch (logoutError) {
        console.error('登出流程失敗:', logoutError)
        errorObj.logoutError = logoutError.message || '登出失敗，請重新整理頁面。'
      }
    }

    // 顯示錯誤訊息
    if (reqConfig.isAlertErrorMsg) {
      setTimeout(() => showErrorMessage(errorObj.msg), 0)
    }

    return errorObj
  } else if (error.request) {
    errorObj.msg = '網絡錯誤，請檢查您的網絡連接。'
    showErrorMessage(errorObj.msg)
  } else {
    errorObj.msg = error.message || '請求發生錯誤。'
    showErrorMessage(errorObj.msg)
  }

  return errorObj
}

// 顯示的錯誤訊息
// 避免 JSON.stringify 循環引用
function safeStringify(obj) {
  let cache = new Set()
  return JSON.stringify(obj, (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (cache.has(value)) {
        return '[Circular]'
      }
      cache.add(value)
    }
    return value
  })
}

function showErrorMessage(message) {
  if (!message) {
    message = '發生未知錯誤'
  }

  if (typeof message === 'object') {
    message = safeStringify(message)
  } else if (typeof message !== 'string') {
    message = message.toString()
  }

  ElMessage({
    message: message,
    type: 'error',
    duration: 3000
  })
}

// 響應攔截器
service.interceptors.response.use(
  (response) => {
    const { data } = response

    // 關閉加載動畫
    if (reqConfig.afHLoading && loadingE) {
      loadingE.close()
    }

    // 如果是下載文件，直接返回響應
    if (reqConfig.isDownLoadFile) {
      return response
    }

    // 解構響應數據
    const { flag, sysMsg: msg, isNeedUpdateToken, updateToken, sysCode: code } = data

    // 更新 Token 以保持登錄狀態
    if (isNeedUpdateToken) {
      setToken(updateToken)
    }

    // 定義成功的狀態碼
    const successCodes = ['0', '200', '20000']

    if (successCodes.includes(String(code))) {
      return data
    } else {
      // 處理 API 返回的錯誤情況
      if (reqConfig.isAlertErrorMsg && !reqConfig.url.includes('/WorkOrder/TicketDelete/')) {
        ElMessage({
          message: code === '403' ? `${msg}，請重新登入` : msg,
          type: 'error',
          duration: 2000,
          onClose: () => {
            if (code === '403') {
              const userStore = useUserStore()
              userStore.resetState().then(() => {
                router.push({ path: '/login' })
              })
            }
          }
        })
      }

      // 拒絕 Promise 並傳遞錯誤數據
      return Promise.reject(data)
    }
  },
  async (error) => {
    // 關閉加載動畫
    if (loadingE) loadingE.close()

    // 使用 handleResponseError 處理錯誤
    const errorObj = await handleResponseError(error, reqConfig)
    return Promise.reject(errorObj)
    // 拒絕 Promise 並傳遞錯誤對象
    /*http错误处理，处理跨域，404，401，500*/

    // const whiteUrls = [
    //   '/Setting/getEpaData/',
    //   '/Reserve/get_config/',
    //   '/WorkOrder/getPredictMileage/'
    //   // 可以在這裡繼續添加其他需要排除的 URL
    // ]
    // if (whiteUrls.some((url) => reqConfig.url.includes(url))) {
    // } else {
    //   ElMessage({
    //     message: err,
    //     type: 'error',
    //     duration: 2 * 1000
    //   })
    // }
    // //如果是跨域
    // //Network Error,cross origin
    // const errObj = {
    //   msg: err.toString(),
    //   reqUrl: reqConfig.baseURL + reqConfig.url,
    //   params: reqConfig.isParams ? reqConfig.params : reqConfig.data
    // }
    // return Promise.reject(JSON.stringify(errObj))
  }
)
// #endregion

export function axiosReq({
  url,
  data,
  method,
  isParams,
  bfLoading,
  afHLoading,
  isUploadFile,
  isDownLoadFile,
  isGuarantee,
  headers,
  baseURL,
  timeout,
  isAlertErrorMsg = true
}) {
  const apiPath = isGuarantee
    ? import.meta.env.VITE_API_GUARANTEE_PATH
    : import.meta.env.VITE_API_PATH
  return service({
    url: url,
    method: method ?? 'get',
    data: data ?? {},
    isParams: isParams ?? false,
    bfLoading: bfLoading ?? false,
    afHLoading: afHLoading ?? true,
    isGuarantee: isGuarantee ?? false,
    isUploadFile: isUploadFile ?? false,
    isDownLoadFile: isDownLoadFile ?? false,
    isAlertErrorMsg: isAlertErrorMsg,
    baseURL: apiPath,
    timeout: timeout ?? 15000,
    headers: headers ?? {}
  })
}

export default axiosReq

// #region 舊的攔截配置
// //响应拦截
// service.interceptors.response.use(
//   (res) => {
//     if (reqConfig.afHLoading && loadingE) {
//       loadingE.close()
//     }
//     // 如果是下载文件直接返回
//     if (reqConfig.isDownLoadFile) {
//       return res
//     }
//     // 保固api回傳的名稱是system_msg
//     const {
//       flag,
//       // [reqConfig.isGuarantee ? 'msg' : 'sysMsg']: msg,
//       [res.data.sysMsg ? 'sysMsg' : 'msg']: msg,
//       isNeedUpdateToken,
//       updateToken,
//       // [reqConfig.isGuarantee ? 'system_code' : 'sysCode']: code
//       [res.data.sysCode ? 'sysCode' : 'system_code']: code
//     } = res.data
//     // const { flag, sysMsg:msg, isNeedUpdateToken, updateToken, sysCode:code } = res.data
//     //更新token保持登录状态
//     if (isNeedUpdateToken) {
//       setToken(updateToken)
//     }
//     const successCode = '0,200,20000'
//     if (successCode.includes(code)) {
//       return res.data
//     } else {
//       // console.log(res)
//       // errorToRealtime(res)
//       // if (code === 403 || code === "403") {
//       //   ElMessageBox.confirm('請重新登入', {
//       //     confirmButtonText: '重新登入',
//       //     cancelButtonText: '取消',
//       //     type: 'warning'
//       //   }).then(() => {
//       //     const userStore = useUserStore()
//       //     userStore.resetState().then(() => {
//       //       router.push({ path: '/login' })
//       //     })
//       //   })
//       // }

//       if (reqConfig.isAlertErrorMsg) {
//         // 排除ticket待修
//         if (!reqConfig.url.includes('/WorkOrder/TicketDelete/')) {
//           ElMessage({
//             message: code == 403 ? msg + '，請重新登入' : msg,
//             type: 'error',
//             duration: 1000,
//             onClose: function () {
//               if (code === 403 || code === '403') {
//                 const userStore = useUserStore()
//                 userStore.resetState().then(() => {
//                   router.push({ path: '/login' })
//                 })
//               }
//             }
//           })
//         }
//       }
//       //返回错误信息
//       //如果未catch 走unhandledrejection进行收集
//       //注：如果没有return 则，会放回到請求方法中.then ,返回的res為 undefined
//       return Promise.reject(res.data)
//     }
//   },
//   (err) => {
//     /*http错误处理，处理跨域，404，401，500*/
//     if (loadingE) loadingE.close()
//     console.log('err ===>' + err) // for debug
//     ElMessage({
//       message: err,
//       type: 'error',
//       duration: 2 * 1000
//     })
//     //如果是跨域
//     //Network Error,cross origin
//     const errObj = {
//       msg: err.toString(),
//       reqUrl: reqConfig.baseURL + reqConfig.url,
//       params: reqConfig.isParams ? reqConfig.params : reqConfig.data
//     }
//     return Promise.reject(JSON.stringify(errObj))
//   }
// )
// #endregion
