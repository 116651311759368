import request from '@/utils/axiosReq'
import { getForWorkOrder } from '@/utils/auth'

const loginStatus = JSON.parse(getForWorkOrder())
export function formItems() {
  return {
    table: [
      {
        prop: 'employeeId',
        placeholder: '僱員ID',
        label: '僱員ID'
      },
      {
        prop: 'employeeName',
        placeholder: '僱員姓名',
        label: '僱員姓名'
      },
      {
        prop: 'employeeBirthday',
        placeholder: '僱員生日',
        label: '僱員生日',
        type: 'date'
      },
      {
        prop: 'employeePhone',
        placeholder: '僱員電話',
        label: '僱員電話',
        type: 'telephone'
      },
      {
        prop: 'employeeAddress',
        placeholder: '僱員通訊地址',
        label: '僱員通訊地址'
      },
      {
        prop: 'employeePermanentAddress',
        placeholder: '僱員戶籍地址',
        label: '僱員戶籍地址'
      },
      {
        prop: 'employeeEmail',
        placeholder: '僱員信箱',
        label: '僱員信箱',
        type: 'email'
      },
      {
        prop: 'employeeEmergencyContact',
        placeholder: '僱員緊急聯絡人',
        label: '僱員緊急聯絡人'
      },
      {
        prop: 'employeeEmergencyContactPhone',
        placeholder: '僱員緊急聯絡人電話',
        label: '僱員緊急聯絡人電話'
      },
      {
        prop: 'employeeLineId',
        placeholder: '僱員Line ID',
        label: '僱員Line ID'
      },
      {
        prop: 'employeeHireDate',
        placeholder: '僱員到職日',
        label: '僱員到職日',
        type: 'date'
      },
      {
        prop: 'employeeLeaveDate',
        placeholder: '僱員離職日',
        label: '僱員離職日',
        type: 'date'
      },
      {
        prop: 'employeeStatus',
        placeholder: '僱員狀態',
        label: '僱員狀態',
        type: 'select',
        options: [
          {
            value: 0,
            label: 'disabled'
          },
          {
            value: 1,
            label: 'enable'
          }
        ]
      }
    ],
    add: [
      {
        prop: 'headquarterId',
        placeholder: '總店ID',
        label: '總店ID',
        disabled: true
      },
      {
        prop: 'branchId',
        placeholder: '分店ID',
        label: '分店ID',
        type: 'select',
        disabled: true
      },
      {
        prop: 'employeeId',
        placeholder: '僱員ID',
        label: '僱員ID'
      },
      {
        prop: 'employeePassword',
        placeholder: '僱員密碼',
        label: '僱員密碼',
        type: 'password'
      },
      {
        prop: 'employeeName',
        placeholder: '僱員姓名',
        label: '僱員姓名'
      },
      {
        prop: 'employeeBirthday',
        placeholder: '僱員生日',
        label: '僱員生日',
        type: 'date'
      },
      {
        prop: 'employeePhone',
        placeholder: '僱員電話',
        label: '僱員電話',
        type: 'telephone'
      },
      {
        prop: 'employeeAddress',
        placeholder: '僱員通訊地址',
        label: '僱員通訊地址'
      },
      {
        prop: 'employeePermanentAddress',
        placeholder: '僱員戶籍地址',
        label: '僱員戶籍地址'
      },
      {
        prop: 'employeeEmail',
        placeholder: '僱員信箱',
        label: '僱員信箱',
        type: 'email'
      },
      {
        prop: 'employeeEmergencyContact',
        placeholder: '僱員緊急聯絡人',
        label: '僱員緊急聯絡人'
      },
      {
        prop: 'employeeEmergencyContactPhone',
        placeholder: '僱員緊急聯絡人電話',
        label: '僱員緊急聯絡人電話'
      },
      {
        prop: 'employeeLineId',
        placeholder: '僱員Line ID',
        label: '僱員Line ID'
      },
      {
        prop: 'employeeHireDate',
        placeholder: '僱員到職日',
        label: '僱員到職日',
        type: 'date'
      },
      {
        prop: 'employeeLeaveDate',
        placeholder: '僱員離職日',
        label: '僱員離職日',
        type: 'date'
      },
      {
        prop: 'employeeStatus',
        placeholder: '僱員狀態',
        label: '僱員狀態',
        type: 'select',
        options: [
          {
            value: 0,
            label: 'disabled'
          },
          {
            value: 1,
            label: 'enable'
          }
        ]
      }
    ],
    edit: [
      {
        prop: 'headquarterId',
        placeholder: '總店ID',
        label: '總店ID',
        disabled: true
      },
      {
        prop: 'branchId',
        placeholder: '分店ID',
        label: '分店ID',
        type: 'select',
        disabled: true
      },
      {
        prop: 'employeeId',
        placeholder: '僱員ID',
        label: '僱員ID',
        disabled: true
      },
      // {
      //   prop: 'employeePassword',
      //   placeholder: '僱員密碼',
      //   label: '僱員密碼',
      //   type: 'password'
      // },
      {
        prop: 'employeeName',
        placeholder: '僱員姓名',
        label: '僱員姓名'
      },
      {
        prop: 'employeeBirthday',
        placeholder: '僱員生日',
        label: '僱員生日',
        type: 'date'
      },
      {
        prop: 'employeePhone',
        placeholder: '僱員電話',
        label: '僱員電話',
        type: 'telephone'
      },
      {
        prop: 'employeeAddress',
        placeholder: '僱員通訊地址',
        label: '僱員通訊地址'
      },
      {
        prop: 'employeePermanentAddress',
        placeholder: '僱員戶籍地址',
        label: '僱員戶籍地址'
      },
      {
        prop: 'employeeEmail',
        placeholder: '僱員信箱',
        label: '僱員信箱',
        type: 'email'
      },
      {
        prop: 'employeeEmergencyContact',
        placeholder: '僱員緊急取車人',
        label: '僱員緊急取車人'
      },
      {
        prop: 'employeeEmergencyContactPhone',
        placeholder: '僱員緊急取車人電話',
        label: '僱員緊急取車人電話'
      },
      {
        prop: 'employeeLineId',
        placeholder: '僱員Line ID',
        label: '僱員Line ID'
      },
      {
        prop: 'employeeHireDate',
        placeholder: '僱員到職日',
        label: '僱員到職日',
        type: 'date'
      },
      {
        prop: 'employeeLeaveDate',
        placeholder: '僱員離職日',
        label: '僱員離職日',
        type: 'date'
      },
      {
        prop: 'employeeStatus',
        placeholder: '僱員狀態',
        label: '僱員狀態',
        type: 'select',
        options: [
          {
            value: 0,
            label: 'disabled'
          },
          {
            value: 1,
            label: 'enable'
          }
        ]
      }
    ],
    reset_password: [
      {
        prop: 'employeeId',
        placeholder: '僱員ID',
        label: '僱員ID',
        disabled: true,
        hidden: true
      },
      {
        prop: 'employeePassword',
        placeholder: '僱員密碼',
        label: '僱員密碼',
        type: 'password'
      }
    ],
    dialog: [
      {
        name: 'add',
        title: '增加'
      },
      {
        name: 'edit',
        title: '編輯僱員'
      },
      {
        name: 'reset_password',
        title: '重設密碼'
      },
      {
        name: 'plan_setting',
        title: '設定方案'
      },
      {
        name: 'manager_edit',
        title: '設定系統管理員'
      },
      {
        name: 'account_add',
        title: '設定管理者'
      },
      {
        name: 'account_edit',
        title: '更改管理者'
      }
    ]
  }
}

export async function getList(inputData) {
  // const _branchId = await JSON.parse(getForWorkOrder());

  return request({
    url: '/Employee/getList',
    method: 'post',
    data: {
      selectCols: '',
      sortCols: '',
      findCols: {
        branchId: loginStatus.branch_id
      }
    },
    bfLoading: false,
    isParams: false,
    isAlertErrorMsg: false
  })
}

export async function getListById(id) {
  return request({
    url: '/Employee/getList',
    method: 'post',
    data: {
      selectCols: '',
      sortCols: '',
      findCols: {
        branchId: id
      }
    },
    bfLoading: false,
    isParams: false,
    isAlertErrorMsg: false,
    headers: {
      Authorization: 'be9b5912-ab29-4e55-88e1-c11b2994fa23' // 某些原因, 這裡是固定的
    }
  })
}

export function edit(inputData) {
  return request({
    url: '/Employee/update/' + inputData.employeeId,
    data: {
      employeePassword: inputData.employeePassword, //僱員密碼
      headquarterId: inputData.headquarterId, //總店ID
      branchId: inputData.branchId, //分店ID
      employeeName: inputData.employeeName, //僱員姓名
      employeeBirthday: inputData.employeeBirthday, //僱員生日
      employeePhone: inputData.employeePhone, //僱員電話
      employeeAddress: inputData.employeeAddress, //僱員通訊地址
      employeePermanentAddress: inputData.employeePermanentAddress, //僱員戶籍地址
      employeeEmail: inputData.employeeEmail, //僱員信箱
      employeeEmergencyContact: inputData.employeeEmergencyContact, //僱員緊急取車人
      employeeEmergencyContactPhone: inputData.employeeEmergencyContactPhone, //僱員緊急取車人電話
      employeeLineId: inputData.employeeLineId, //僱員Line ID
      employeeHireDate: inputData.employeeHireDate, //僱員到職日
      employeeLeaveDate: inputData.employeeLeaveDate, //僱員離職日
      employeeStatus: inputData.employeeStatus //僱員狀態 0: disable, 1: enable
    },
    method: 'post',
    bfLoading: false,
    isParams: false,
    isAlertErrorMsg: false
  })
}

export function add(inputData) {
  return request({
    url: '/Employee/create',
    data: {
      employeeId: inputData.employeeId, //	僱員ID
      employeePassword: inputData.employeePassword, //	僱員密碼
      headquarterId: inputData.headquarterId, //	總店ID
      branchId: inputData.branchId, //	分店ID
      employeeName: inputData.employeeName, //	僱員姓名
      employeeBirthday: inputData.employeeBirthday, //	僱員生日
      employeePhone: inputData.employeePhone, //	僱員電話
      employeeAddress: inputData.employeeAddress, //	僱員通訊地址
      employeePermanentAddress: inputData.employeePermanentAddress, //	僱員戶籍地址
      employeeEmail: inputData.employeeEmail, //	僱員信箱
      employeeEmergencyContact: inputData.employeeEmergencyContact, //	僱員緊急取車人
      employeeEmergencyContactPhone: inputData.employeeEmergencyContactPhone, //	僱員緊急取車人電話
      employeeLineId: inputData.employeeLineId, //	僱員Line ID
      employeeHireDate: inputData.employeeHireDate, //	僱員到職日
      employeeLeaveDate: inputData.employeeLeaveDate, //	僱員離職日
      employeeStatus: inputData.employeeStatus //	僱員狀態 0: disable, 1: enable
    },
    method: 'post',
    bfLoading: false,
    isParams: false,
    isAlertErrorMsg: false
  })
}

export function del(id) {
  return request({
    url: '/Employee/delete/' + id,
    method: 'post',
    bfLoading: false,
    isParams: false,
    isAlertErrorMsg: false
  })
}

export function getDetail(id) {
  return request({
    url: '/Employee/get/' + id,
    method: 'get',
    bfLoading: false,
    isParams: false,
    isAlertErrorMsg: false
  })
}

export function getSimpleReport() {
  return request({
    url: '/Employee/getSimpleReport/' + loginStatus.employee_id,
    method: 'get',
    bfLoading: false,
    isParams: false,
    isAlertErrorMsg: false
  })
}

export function updatePassword(inputData) {
  return request({
    url: '/Employee/updatePassword',
    data: {
      employeeId: inputData.employeeId, //	僱員ID
      employeePassword: inputData.employeePassword //	僱員密碼
    },
    method: 'post',
    bfLoading: false,
    isParams: false,
    isAlertErrorMsg: false
  })
}
